@import "variables";

// Link Styles ~elements/Link.jxs
.link{
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  color: $primary-color;
  &:hover{
    opacity: 0.7;
    color: $primary-color;
  }
  &_underline{
    position: relative;
    &:after{
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 0;
      height: 1.1px;
      background-color: $primary-color;
      transition: width 0.3s ease-in-out;
    }
    &:hover{
      opacity: 1;
      &:after{
        width: 100%;
      }
    }
  }
}

// adding margins for react table in all components
.rt-tr {
  margin-bottom: 12px;
}

// custom textarea element in InvoiceEditForm
.custom-textarea {
  cursor: auto !important;
  background-color: transparent !important;
  border: none !important;
  resize: none !important;
}

// text editor styles ~ containers/Task/TaskDetailed/TaskEditor.jsx
.text-editor-loader-container {
  height: 300px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-editor-container {
  min-height: 300px;
 & .tox-statusbar__text-container {
   @media screen and (max-width: 600px){
     & a {
       display: none !important;
     }
   }
  }
}
//invoices-title styles ~IinvoiceEditForm.jsx 105
.invoice-edit-title {
  display: flex;
  justify-content: space-between;
}
//Loading styles ~elements/Loader.jsx
.lds-spinner-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
    .lds-spinner {
    color:  #444444;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #444444;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
// Status Styles ~elements/Styles.jsx
.status{
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  border: 2px solid $black-color;
  padding: 0 10px;
  &__item{
    color: $black-color;
    font-size: 12px;
    padding: 5px;
    display: inline-flex;
    align-items: center;
    svg{
      margin-left: 10px;
    }
    &:last-child{
      svg{
        display: none;
      }
    }
  }
}

// SVG Styles ~assets/svg/svg.js
.svg{
  display: inline-flex;
  align-items: center;
}

// Empty Component Styles
.empty-text{
  color: $gray-color;
  font-size: 14px;
}

//Nav pills Styles
.nav.nav-pills{
  .nav-item{
    padding: 5px 10px;
    a.nav-link{
      border: 1px solid $primary-color;
      color: $primary-color;
      background-color: $primary-color-invert;
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
      &:hover{
        opacity: 0.7;
      }
      &.active{
        opacity: 1;
        color: $primary-color-invert;
        background-color: $primary-color;
      }
    }
  }
}

// Custom Button Styles
.btn.btn-primary{
  &:disabled{
    color: $primary-color;
  }
}

// Inputs Styles
.input-box{
  background: rgba(68, 125, 247, 0.2);
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  margin-bottom: 0;
  &__label{
    padding-left: 5px;
    color: rgba(0,0,0,0.5)!important;
  }
  &__group{
    .input-group-text{
      border-color: rgba(68, 125, 247, 0.2);
    }
  }
  &__input{
    border-color: #ffffff;
    &:hover{
      border-color: rgba(68, 125, 247, 0.5);
    }
    &:focus{
      border-color: rgba(68, 125, 247, 1);
    }
  }
  &__desc{
    margin-top: 10px;
    padding-left: 5px;
  }
  &__inner{
    position: relative;
    &-clear{
      display: flex;
      min-width: 30px;
      max-width: 30px;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      cursor: pointer;
      path{
        transition: 0.3s ease-in-out;
      }
      &:hover{
        path{
          fill: rgba(0,0,0,.9);
        }
      }
    }
    &-group{
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: 2;
      display: inline-block;
      padding: 0 5px;
      min-width: 35px;
      max-width: 35px;
      pointer-events: none;
      background-color: #e9ecef;
      font-size: 10px;
      border: 1px solid rgba(68, 125, 247, 0.2);
      border-radius: 4px 0 0 4px;
      text-transform: uppercase;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 38px;
      text-align: center;
    }
  }
  &__error{
    margin-top: 10px;
    padding-left: 5px;
    font-size: 80%;
    color: #dc3545;
    width: 100%;
    display: inline-block;
  }

  // DatePicker Customization in .input-box
  .date-picker{
    .form-control{
      border-color: #ffffff;
      height: 40px;
      &:hover{
        border-color: rgba(68, 125, 247, 0.5);
      }
      &:focus{
        border-color: rgba(68, 125, 247, 1);
      }
    }
    &_error{
      .form-control{
        border-color: #dc3545;
      }
    }
    &_clear-btn{
      .form-control{
        padding-right: 22px;
      }
    }
    &_with-group{
      .form-control{
        padding-left: 40px;
      }
    }
    &.rdtOpen{
     .form-control{
       &:focus{
         border-color: rgba(68, 125, 247, 1);
       }
     }
    }
  }

  // ReactSelect Customization in .input-box
  .react-select{
    & > div{
      border-color: rgba(68, 125, 247, 0.2);
      &:hover{
        border-color: rgba(68, 125, 247, 0.5);
      }
    }
    &_error{
      & > div{
        border-color: #dc3545;
      }
    }
  }
  // Editor Customization in .input-box
  .react-editor-wrapper{
    .rdw-editor-toolbar{
      margin-bottom: 0;
      border-color: rgba(68, 125, 247, 0.2);
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    .react-editor{
      background-color: #fff;
      padding: 10px;
      border: 1px solid rgba(68, 125, 247, 0.2);
      border-top: 0;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
  // Checkbox Customization in .input-box
  &_checkbox{
    .input-checkbox{
      label{
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  &_small{
    padding: 4px;
    .form-control{
      font-size: 12px;
    }
    .input-box__label{
      font-size: 10px!important;
      margin-bottom: 3px!important;
    }
    .react-select{
      font-size: 12px;
    }
    .date-picker{
      .form-control{
        font-size: 12px;
        height: 38px;
      }
    }
  }
  &_space{
    height: 75px;
    padding: 5px 10px;
    background-color: transparent;
  }
}

// Button Styles
.primary-button{
  min-width: auto;
  white-space: nowrap;
  &_small{
    padding: 5px 10px;
    font-size: 14px;
  }
  &_normal{
    padding: 6px 12px;
    font-size: 16px;
  }
  &_icon{
    &-left{
      i{
        margin-right: 5px;
      }
    }
    &-right{
      i{
        margin-left: 5px;
      }
    }
  }
}
// Rater Styles
.rater{
  min-width: 82px;
}

// Errors Box
.errors_box{
  margin-bottom: 0;
}
// Modal styles
.modal{
  &-content{

  }
  &-header{

  }
  &-title{
    &.h4{
      margin: 0;
    }
  }
}
.to-hover{
  color: rgba(68, 125, 247, 0.8);
  font-weight: 600;
  cursor: default;
}
.hint-text{
  color: #447DF7;
  font-style: normal;
  border-bottom: 1px dashed #447DF7;
}
.__react_component_tooltip{
  white-space: normal;
  max-width: 500px;
}
.hint-item{
  margin-bottom: 5px;
  border-bottom: 1px solid #ffffff;
  padding: 2px 5px 5px 5px;
  width: 100%;
  &:last-child{
    padding-bottom: 0;
    border-bottom: none;
  }
  &__label{
    margin-right: 5px;
    font-weight: 600;
  }
  p{
    margin: 2px 0;
    font-size: 12px;
  }
}
@media screen and (max-width: 600px){
  .status{
    padding: 0 5px;
    &__item{
      text-align: center;
      svg{
        margin-left: 5px;
      }
    }
  }

  //Nav pills Styles Mobile
  .nav.nav-pills{
    .nav-item{
      padding: 10px;
      flex-grow: 1;
      text-align: center;
    }
  }
}
