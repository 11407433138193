@import "variables";

// REUSABLE WRAPPERS
.panel-list{
  &__header{
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid $border-color;
  }
}
.custom-grid{
  display: flex;
  width: 100%;
  flex-direction: column;
  &__row{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    &_v-center{
      align-items: center;
    }
    &_v-end{
      align-items: flex-end;
    }
    &_h-center{
      justify-content: center;
    }
    &_h-end{
      justify-content: flex-end;
    }
  }
  &__col{
    padding: 10px;
    box-sizing: border-box;
    display: block;
    flex-grow: 1;
    margin: 0;
    flex-basis: 1px;
    min-width: 200px;
    &_v-center{
      align-self: center;
    }
    &_v-end{
      align-self: flex-end;
    }
    &_h-center{
      justify-self: center;
    }
    &_h-end{
      justify-self: flex-end;
    }
    &_grow-0{
      flex-grow: 0;
      min-width: auto;
    }
    &_sep-right{
      position: relative;
      &:before{
        content: '';
        position: absolute;
        display: block;
        height: 60%;
        border-radius: 2px;
        top: 20%;
        width: 2px;
        background-color: #447df7;
        right: 0;

      }
    }
    &_full-width{
      width: 100%;
    }
  }
  &__row-label{
    width: 100%;
    display: inline-block;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0.45px;
    color: #818181;
    margin: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px;
  }
  &_small{
    .custom-grid__col{
      padding: 4px;
    }
  }
}
.card-box{
  &__title{
    font-size: 30px;
    color: $black-color;
    margin: 0;
    padding: 2px;
  }
  &__sub-title{
    font-size: 26px;
    opacity: 0.7;
    color: $black-color;
    margin: 0;
  }
  &__list{
    display: flex;
    flex-direction: column;
  }
  &__text{
    padding: 2px;
    font-size: 16px;
    color: $black-color;
    margin-bottom: 5px;
    display: inline-flex;
    align-items: flex-start;
    &:last-child{
      margin-bottom: 0;
    }
    &:before{
      //content: '';
      margin-top: 9px;
      min-width: 5px;
      max-width: 5px;
      min-height: 5px;
      max-height: 5px;
      border-radius: 50%;
      background-color: $gray-color;
      display: inline-block;
      margin-right: 7px;
    }
  }
}
.inputs-group{
  .input-box{
    padding: 5px 10px;
    background-color: transparent;
  }
  &__row{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  &__title{
    font-size: 14px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.7);
    font-weight: 600;
    padding: 10px 15px;
  }
  padding: 5px 0;
  background-color: rgba(68, 125, 247, 0.2);
  border-radius: 4px;
}

// CUSTOM WRAPPERS
.task-comments-form{
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid $border-color;
  textarea{
    resize: none;
  }
  button{
    margin-left: auto;
  }
}
.uploads-task{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &-column{
    &-wrap{
      width: 33.33%;
      padding: 10px;
      &_full{
        width: 100%;
      }
    }
    &.dnd-wrapper{
      transition: 0.3s ease-in-out;
      box-sizing: content-box;
      &_can-drop{
        border-color: #447df7;
      }
      &_is-ative{
        border-color: #87cb16;
      }
    }
    padding: 10px;
    border: 2px solid $border-color;
    border-radius: 4px;
    &__header{
      border-bottom: 1px solid $border-color;
      padding: 5px 10px 15px 10px;
      align-items: center;
      width: 100%;
      span{
        color: $gray-color;
      }
      svg{
        margin-right: 8px;
      }
    }
    &__list{
      .text-muted{
        display: block;
        width: 100%;
        text-align: center;
        padding: 10px 0;
        font-size: 14px;
        opacity: 0.6;
      }
    }
  }
  &-item{
    margin: 10px 0;
    border: 1px solid $border-color;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-radius: 4px;
    overflow: hidden;
    transition: 0.3s ease-in-out;
    &__content{
      display: flex;
      width: 100%;
      padding: 10px 0;
      align-items: flex-start;
      &_disable{
        padding: 10px;
        width: 100%;
      }
      .uploads-task-item__title{
        max-width: calc(100% - 105px);
        font-size: 12px;
        font-weight: 600;
      }
      .uploads-task-item__time{
        font-size: 10px;
        font-weight: normal;
      }
    }
    &__drag-btn{
      padding: 5px 10px;
      cursor: move;
      display: flex;
      justify-content: center;
    }
    &__action-btn{
      padding: 5px 0;
      cursor: pointer;
      width: 30px;
      min-width: 30px;
      height: 30px;
      justify-content: center;
      display: flex;
      border: 0;
      background-color: transparent;
      outline: none!important;
    }
    &__title{
      flex-grow: 1;
      color: $gray-color;
      padding: 0 5px;
      max-width: calc(100% - 100px);
      align-self: center;
      a{
        margin-right: 10px;
      }
    }
    &_dragging{
      opacity: 0.3;
    }
  }
}

.task-edit-wrapper{
  .inputs-group{
    margin-bottom: 20px;
    &:last-child{
      margin-bottom: 0;
    }
    &__row{
      .input-box{
        .input-group-append{
          max-width: 100px;
          overflow: hidden;
          span{
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            padding-left: 5px;
            padding-right: 5px;
          }
        }
        &__title{
          margin-bottom: 4px;
        }
        &:first-child{
          padding-right: 0;
          min-width: 100px;
        }
        &:last-child{
          flex-grow: 1;
        }
      }
    }
    .input-box_checkbox{
      padding: 10px;
    }
  }
}
// RESPONSIVE
@media screen and (max-width: 800px){
  .uploads-task{
    &-column {
      &-wrap {
        width: 100%;
        padding: 10px 0;
      }
    }
  }
}
.uploads-task-item__btns{
  min-width: 60px;
  display: flex;
  .uploads-task-item__action-btn-delete {
    margin-top: 5px;
  }
}
