.smaller-card {
  margin-bottom: 5px;
  > .content {
    padding: 5px;
  }
}

.card-scheduler {
  overflow-x: scroll;
  height: calc(100vh - 210px);

  .content {
    height: 100%;

    > div {
      height: 100%;
    }
  }

  .ReactVirtualized__Table__row {
    border-bottom: 1px solid #dddddd;
  }

  .ReactVirtualized__Table__headerTruncatedText {
    white-space: normal;
  }

  .table-header {
    font-weight: 600;
    font-size: 12px;
    text-align: center;

    justify-content: center;
    align-items: center;
    display: flex;
  }
  .colored-cell-container {
    margin-right: 0;
    height: 100%;
  }
  .colored-cell {
    flex: 1;
    text-align: center;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
  }


  .planned-load-column {
    background-color: lightblue;
    margin-right: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
  }
  .table-cell {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .planned-load-input {
    width: 80%;
  }
}

.user-calendar-container {
  overflow-x: scroll;
  min-height: 100px;

  .colored-cell-container {
    padding: 10px;
  }
  .colored-header-container {
    padding: 0 10px 0 10px;
  }
}

.full {
  background-color: yellow;
}

.dayoff {
  background-color: #d3d3d3;
}

.partial {
  background-color: palegreen;
}

.no-data {
  background-color: white;
}
