@import "variables";

$message-img-size: 50px;

.user-message{
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 10px 20px;
  border-bottom: 1px solid $border-color;
  &:last-child{
    border-bottom: 0;
  }
  &__img{
    max-width: $message-img-size;
    min-width: $message-img-size;
    height: $message-img-size;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    border: 3px solid #444444;
    overflow: hidden;
    img, svg{
      max-width: 100%;
    }
  }
  &__content{
    width: calc(100% - #{$message-img-size});
  }
  &__header{
    width: 100%;
    display: flex;
    align-items: center;
  }
  &__title{
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    padding-right: 10px;
    font-size: 20px;
    a{
      color: rgba(0,0,0,0.7);
    }
  }
  &__time{
    white-space: nowrap;
    color: #9a9a9a;
    font-size: 12px;
  }
  &__assign{
    display: inline-block;
    margin-top: 10px;
    color: #9a9a9a;
    font-size: 12px;
    width: 100%;
  }
  &__status-box{
    margin-top: 5px;
  }
  &__status-title{
    margin: 0 0 5px 0;
    color: #9a9a9a;
    font-size: 14px;
  }
  &__text{
    margin-top: 10px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
    p{
      font-size: 14px;
      color: rgba(0, 0, 0, 0.7);
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 600px){
  .user-message{
    &__img{
      display: none;
    }
    &__content{
      width: 100%;
    }
    &__header{
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
    &__title{
      margin-bottom: 10px;
      padding: 0;
      white-space: normal;
    }
    &__status-title{
      font-size: 14px;
    }
    &__text{
      font-size: 12px;
    }
  }
}
