.ReactTable{
  .rt-noData{
    z-index: auto;
  }
}

// Editor
.public-DraftStyleDefault-block{
  margin: 0!important;
}

.btn.btn-small {
  padding: 2px 5px;
  margin-right: 5px;
}

//Dropdown Menu
.dropdown-menu{
  a{
    position: relative;
    z-index: 2;
    &:first-child{
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    &:last-child{
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}
.dropdown-right {
  .dropdown-menu:before {
    margin-left:80%;
  }
  .dropdown-menu:after {
    margin-left:80%;
  }
}
//Theme Customization
.sidebar{
  .logo{
    &-img{
      img{
        top: -13px;
        width: 35px;
      }
    }
    a.logo-mini{
      margin-left: 23px;
    }
  }
}
