@import "variables";

.deadline-expired {
  color: red;
  font-weight: bold;
}
.table-task{
  &__col{
    &_name{
      display: flex;
      flex-wrap: wrap;
      & > a{
        white-space: normal;
        font-size: 18px;
        font-weight: 600;
        display: inline-flex;
        width: 100%;
        color: $primary-color;
        margin-bottom: 5px;
      }
      & > span{
        font-size: 13px;
        margin-right: 8px;
        & > span{
          color: $gray-color;
          margin-right: 3px;
        }
        a{}
      }
      &:last-child{
        margin-right: 0;
      }
    }
    &_status{
      display: flex;
      flex-wrap: wrap;
      & > h4{
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        width: 100%;
        padding: 5px 0;
        margin: 0;
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        white-space: normal;
        span{
          font-size: 14px;
          margin-left: 5px;
          font-weight: 600;
          &:before{
            content: '- ';
            color: $black-color;
          }
        }
      }
      & > span{
        font-size: 13px;
        color: $gray-color;
        display: inline-block;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      & > div{
        font-size: 13px;
        color: $gray-color;
        display: inline-block;
        width: 100%;
        white-space: normal;
        span{
        }
      }

    }
    &_last-action{
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      width: 100%;
      padding: 5px 0;
      margin: 0;
      white-space: normal;
    }
    &_actions{
      display: flex;
      justify-content: center;
      a{
        font-size: 28px;
        margin-right: 15px;
        &:last-child{
          margin-right: 0;
        }
      }
    }
    &_deadline{
      position: relative;
      text-align: center;
      span{
        margin-left: -1px;
        &.text-warning{
          border-bottom: 1px #ffa534 dashed;
        }
        &.text-danger{
          border-bottom: 1px #fb404b dashed;
        }
        &.text-success{
          border-bottom: 1px #87cb16 dashed;
        }
      }
      &:after{
        right: -5px;
        top: 0;
        content: '';
        display: block;
        width: 2px;
        border-radius: 2px;
        height: 100%;
        background-color: #9a9a9a;
        position: absolute;
      }
      &_client{
        &:after{
          content: none;
        }
      }
    }
  }
}
.users-table{
  &__col{
    &_actions{
      display: flex;
      justify-content: center;
      a{
        font-size: 28px;
        margin-right: 15px;
        &:last-child{
          margin-right: 0;
        }
      }
    }
  }
}
.clients-table{
  &__col{
    &_actions{
      display: flex;
      justify-content: center;
      a{
        font-size: 28px;
        margin-right: 15px;
        &:last-child{
          margin-right: 0;
        }
      }
    }
  }
}
.invoices-table{
  &__col{
    &_actions{
      display: flex;
      justify-content: center;
      a{
        font-size: 28px;
        margin-right: 15px;
        &:last-child{
          margin-right: 0;
        }
      }
    }
  }
}




// FILTERS
.task-list-filters{
  &__search{
    flex-grow: 3;
    input {
      height: 36px;
      margin-top: 1px;
      margin-bottom: 1px;
    }
    .input-group-text {
      font-size: 12px;
    }
  }
  &__datepickers{
    min-width: 250px;
    flex-grow: 2;
  }
  &__select{
    min-width: 150px;
    flex-grow: 1;
  }
  .inputs-group{
    padding: 4px;
    &__title{
      font-size: 10px;
      margin-bottom: 3px;
      color: rgba(0, 0, 0, 0.5) !important;
      font-weight: 500;
      padding: 0 0 0 5px;
    }
    &__row{
      display: flex;
      .input-box{
        padding: 0 4px 0 0;
        width: 50%;
        &:last-child{
          padding-right: 0;
        }
      }
    }
  }
}


.users-list-filters{
  &__search{
    flex-grow: 3;
  }
  &__datepickers{
    min-width: 250px;
    flex-grow: 2;
  }
  .inputs-group{
    padding: 4px;
    &__title{
      font-size: 10px;
      margin-bottom: 3px;
      color: rgba(0, 0, 0, 0.5) !important;
      font-weight: 500;
      padding: 0 0 0 5px;
    }
    &__row{
      display: flex;
      .input-box{
        padding: 0 4px 0 0;
        width: 50%;
        &:last-child{
          padding-right: 0;
        }
      }
    }
  }
}
.invoices-list-filters{
  &__search{
    flex-grow: 3;
  }
  &__datepickers{
    min-width: 250px;
    flex-grow: 2;
  }
  .inputs-group{
    padding: 4px;
    &__title{
      font-size: 10px;
      margin-bottom: 3px;
      color: rgba(0, 0, 0, 0.5) !important;
      font-weight: 500;
      padding: 0 0 0 5px;
    }
    &__row{
      display: flex;
      .input-box{
        padding: 0 4px 0 0;
        width: 50%;
        &:last-child{
          padding-right: 0;
        }
      }
    }
  }
}

.tooltip-transitions {
  opacity: 1;
  .transition-list--wrapper {
    background: white;
    color: rgba(0, 0, 0, 0.7);
    .transition-list--item {
      opacity: 0.8;
      text-align: left;
      margin-top: 10px;
      cursor: pointer;
      margin-bottom: 10px;
    }
    .transition-list--item:hover {
      opacity: 1;
    }
  }
}

.tasks-1000-wrapper {
  position: absolute;
  bottom: 20px;
  padding-left:10px;
}
.superspinner {
  position: fixed;
  top:40%;
  left:40%;
  width: 200px;
  height: 200px;
}
